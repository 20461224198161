import { ReportByUser } from "./../types/report";
import {
  deleteReportByID,
  getAllReportByMonthApi,
  getReportsToday,
  getReportsYesterday,
  sendReport,
} from "api/reports";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addListReportToday,
  removeListReportToday,
  setListReportToday,
  setListReportYesterday,
} from "store/modules/report/report";
import { selectors } from "store/seletors";
import { ReportItem } from "types/report";
export const useListReports = () => {
  // const [reportsToday, setReportsToday] = useState<ReportItem[]>([])
  // const [reportsYesterday, setReportsYesterday] = useState<ReportItem[]>([])
  const membersLuxTech = useSelector(selectors.user.getListUser);
  const projectsLuxTech = useSelector(selectors.project.getListProjects);

  const reportsToday = useSelector(selectors.report.getListReportsToday);
  const reportsYesterday = useSelector(
    selectors.report.getListReportsYesterday
  );
  const [reportsByMonthState, setReportsByMonthState] =
    useState<ReportItem[]>();
  const dispatch = useDispatch();

  // calculate report today
  const userReportedToday = reportsToday.map((report) => report.email);

  const employeesLuxTech = membersLuxTech.filter(
    (item) => item.role !== "admin"
  );

  const userNotYetReportToday = employeesLuxTech.filter(
    (employee) => !userReportedToday.includes(employee.email)
  );

  const haveEmployeesReportedToday =
    employeesLuxTech.length - userNotYetReportToday.length;

  // calculate report yesterday
  const userReportedYesterday = reportsYesterday.map((report) => report.email);

  const userNotYetReportYesterday = employeesLuxTech.filter(
    (employee) => !userReportedYesterday.includes(employee.email)
  );

  const haveEmployeesReportedYesterday =
    employeesLuxTech.length - userNotYetReportYesterday.length;

  const getAllReportByMonth = async () => {
    const result = await getAllReportByMonthApi();
    const reports = result?.data;
    const reportsByMonth = reports.filter((item) =>
      item.createdAt.includes("2024-12")
    );
    setReportsByMonthState(reportsByMonth);
  };
  const getReportsDataToday = async () => {
    const result = await getReportsToday();
    const data = result?.data;
    if (data) {
      dispatch(setListReportToday(data));
    }
  };

  const getReportsDataYesterday = async () => {
    const result = await getReportsYesterday();
    const data = result?.data;
    if (data) {
      dispatch(setListReportYesterday(data));
    }
  };

  const sendReportByThisUser = async (data: ReportByUser) => {
    const result = await sendReport(data);
    const res = result.data;
    if (res.status === "0") {
      toast.error(res.msg);
      return true;
    }

    if (res.status === "1") {
      toast.success(res.msg);
      dispatch(addListReportToday(res.report));
      return true;
    }
    return false;
  };

  const handleRemoveReport = async (id: string) => {
    const result = await deleteReportByID(id);
    if (result.data) {
      toast.success("Remove report Success!");
      dispatch(removeListReportToday(result.data?.id));
    } else {
      toast.error("Remove report failed!");
    }
  };

  return {
    employeesLuxTech,
    reportsToday,
    reportsYesterday,
    haveEmployeesReportedToday,
    haveEmployeesReportedYesterday,
    userNotYetReportToday,
    userNotYetReportYesterday,
    projectsLuxTech,
    reportsByMonthState,
    getReportsDataToday,
    sendReportByThisUser,
    handleRemoveReport,
    getReportsDataYesterday,
    getAllReportByMonth,
  };
};
